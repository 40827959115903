import { STATUS, STATUS_NAME } from '../constants'
import languageList from '../constants/languages'

export const isArrayEqual = (array1, array2) => {
	return array1.sort().toString() === array2.sort().toString()
}

export const truncate = (input, quantity) =>
	input?.length > quantity ? `${input.substring(0, quantity)}...` : input

export const hasRoleOrPermission = (
	requiredRoles,
	userRoles
	// userPermissions
) => {
	let hasRole = false
	if (!requiredRoles) {
		return true
	}
	hasRole = userRoles.some((role) => requiredRoles.includes(role))
	return hasRole
	// let hasPermission = false
	// if (requiredPermission) {
	// 	hasPermission = userPermissions.some((permission) =>
	// 		requiredPermission.includes(permission)
	// 	)
	// }
	// return hasRole || hasPermission
}

export const readFile = (file) => {
	return new Promise((resolve) => {
		const reader = new FileReader()
		reader.addEventListener('load', () => resolve(reader.result), false)
		reader.readAsDataURL(file)
	})
}

export const getRoleLabel = (roleRaw) => {
	const role = typeof roleRaw === 'string' ? roleRaw : roleRaw.name
	switch (role) {
		case 'ROLE_ADMIN':
			return 'Administrador'
		case 'ROLE_USER':
			return 'Usuário'
		case 'ROLE_ORGANIZATION':
			return 'Organização'
		case 'ROLE_CURADOR_CHEFE':
			return 'Curador-chefe'
		case 'ROLE_CURADOR':
			return 'Curador'
		default:
			return ''
	}
}

export const isSafari = () => {
	return (
		navigator.vendor &&
		navigator.vendor.indexOf('Apple') > -1 &&
		navigator.userAgent &&
		navigator.userAgent.indexOf('CriOS') == -1 &&
		navigator.userAgent.indexOf('FxiOS') == -1
	)
}

export const slugify = (text) => {
	if (!text) {
		return ''
	}
	return text
		.toString()
		.normalize('NFD')
		.replace(/[\u0300-\u036f]/g, '')
		.toLowerCase()
		.trim()
		.replace(/\s+/g, '-')
		.replace(/[^\w-]+/g, '')
		.replace(/--+/g, '-')
}

export const getTmpId = () => {
	return `tmp_${Math.floor(Math.random() * 1000000)}`
}

export const isTmpId = (id) => {
	if (!id) {
		return true
	}
	return id.toString().slice(0, 4) === 'tmp_'
}

export const getJustification = (item) => {
	switch (item.status) {
		case STATUS.SOLICITADA_CORRECAO:
			return item.correct_justification
		case STATUS.APROVADO:
			return item.approve_justification
		case STATUS.REJEITADO:
			return item.reprove_justification
		case STATUS.REJEITADA_ANALISE:
			return item.reject_analysis_justification
		default:
			return ''
	}
}

export const getJustificationAlertType = (item) => {
	switch (item.status) {
		case STATUS.SOLICITADA_CORRECAO:
		case STATUS.AGUARDANDO_APROVACAO:
			return 'warning'
		case STATUS.APROVADO:
			return 'success'
		case STATUS.REJEITADO:
			return 'error'
		case STATUS.REJEITADA_ANALISE:
			return 'error'
		default:
			return 'info'
	}
}

export const getStatusName = (status) => {
	if (status === false || status === null || !(status in STATUS_NAME)) {
		return ''
	}
	return STATUS_NAME[status]
}

export const getStatusAlertTitle = (item) => {
	const typeName = item.collection_type
		? item.collection_type.name
		: 'Conteúdo'
	const typeGenre = item.collection_type ? item.collection_type.genre : 'o'
	switch (item.status) {
		case STATUS.APROVADO:
			return `${typeName} aprovad${typeGenre}`
		case STATUS.REJEITADO:
			return `${typeName} rejeitad${typeGenre}`
		case STATUS.REJEITADA_ANALISE:
			return `${typeGenre.toUpperCase()} ${typeName.toLowerCase()} teve sua análise rejeitada`
		case STATUS.SOLICITADA_CORRECAO:
		case STATUS.AGUARDANDO_APROVACAO:
		default:
			return getStatusName(item.status)
	}
}

export const getStatusClassName = (params) => {
	switch (params.row.status) {
		case STATUS.AGUARDANDO_APROVACAO:
			return 'yellow-status'
		case STATUS.APROVADO:
			return 'green-status'
		case STATUS.REJEITADO:
		case STATUS.REJEITADA_ANALISE:
			return 'red-status'
		case STATUS.SOLICITADA_CORRECAO:
			return 'red-status'
		case STATUS.EXCLUIDO:
			return 'red-status'
		case STATUS.RASCUNHO:
			return ''
		default:
			return ''
	}
}

export const getLanguagesForDropdown = () =>
	languageList
		.map((row) => ({
			id: row.id,
			name: row.portugueseName
		}))
		.sort(function (x, y) {
			return x.name === 'Brasil' ? -1 : y.name === 'Brasil' ? 1 : 0
		})

export function cleanMarkdown(markdown) {
	// Remove code blocks
	let cleaned = markdown.replace(/```[\s\S]*?```|`[^`]*`/g, '')
	// Remove images and links
	cleaned = cleaned.replace(/!\[.*?\]\(.*?\)|\[.*?\]\(.*?\)/g, '')
	// Remove bold, italic, and strikethrough
	cleaned = cleaned.replace(
		/(\*\*|__)(.*?)\1|\*(.*?)\*|_(.*?)_|~~(.*?)~~/g,
		'$2$3$4$5'
	)
	// Remove headings
	cleaned = cleaned.replace(/^#{1,6}\s+/gm, '')
	// Remove blockquotes
	cleaned = cleaned.replace(/^>\s+/gm, '')
	// Remove unordered and ordered list markers
	cleaned = cleaned.replace(/^(\s*[-*+]|\d+\.)\s+/gm, '')
	// Remove horizontal rules
	cleaned = cleaned.replace(/^([-_*]){3,}\s*$/gm, '')
	// Remove extra spaces or newlines
	cleaned = cleaned.replace(/\s{2,}/g, ' ').trim()

	return cleaned
}

export function fetchYouTubeCode(item) {
	console.log(item.content_link)
	const youTubeCode = !item.content_link
		? undefined
		: item.content_link.includes('youtube.com/')
		? item.content_link.split('v=')[1].split('&')[0]
		: item.content_link.includes('youtu.be/')
		? item.content_link.split('youtu.be/')[1].split('?')[0]
		: undefined
	return youTubeCode || undefined
}
