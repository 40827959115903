import { authHeader } from '../auth-header'
import { handleResponse } from '../handle-response'

const config = {
	apiUrl: process.env.REACT_APP_API
}

export const collectionsService = {
	getAllCollections,
	getCollectionsByType,
	createCollectionByType,
	getCollectionById,
	updateCollection,
	getAllCollectionsByUser,
	getCollectionsByTypeAndUser,
	setCover,
	deleteCollection,
	getCollectionByUrl,
	sendCollectionToApproval,
	getAllCollectionsToApproval,
	getAllPendingCollectionsByType,
	setCurator,
	publishCollection,
	defineContent,
	actionContent
}

function getAllCollections(page, perPage) {
	const requestOptions = {
		method: 'GET',
		headers: authHeader()
	}

	return fetch(
		`${config.apiUrl}/collections/paginate/${page}/${perPage}`,
		requestOptions
	).then(handleResponse)
}

function getCollectionsByType(
	collectionType,
	page,
	perPage,
	sortColumn = null,
	sortDirection = null,
	search = null
) {
	const requestOptions = {
		method: 'GET',
		headers: authHeader()
	}

	let url = sortColumn
		? `${config.apiUrl}/collections/${collectionType}s/paginate/${page}/${perPage}/${sortColumn}/${sortDirection}`
		: `${config.apiUrl}/collections/${collectionType}s/paginate/${page}/${perPage}`
	if (search) {
		url += `?q=${search}`
	}
	return fetch(url, requestOptions).then(handleResponse)
}

function createCollectionByType(collectionTypeSlug, collection) {
	const requestOptions = {
		method: 'POST',
		headers: { ...authHeader(), 'Content-Type': 'application/json' },
		body: JSON.stringify(collection)
	}

	return fetch(
		`${config.apiUrl}/collections/create/${collectionTypeSlug}`,
		requestOptions
	).then(handleResponse)
}

function updateCollection(collectionId, collection) {
	const requestOptions = {
		method: 'PUT',
		headers: { ...authHeader(), 'Content-Type': 'application/json' },
		body: JSON.stringify(collection)
	}

	return fetch(
		`${config.apiUrl}/collections/${collectionId}`,
		requestOptions
	).then(handleResponse)
}

function getCollectionById(collectionId) {
	const requestOptions = {
		method: 'GET',
		headers: { ...authHeader(), 'Content-Type': 'application/json' }
	}

	return fetch(
		`${config.apiUrl}/collections/${collectionId}`,
		requestOptions
	).then(handleResponse)
}

function getAllCollectionsByUser(idUser, page, perPage) {
	const requestOptions = {
		method: 'GET',
		headers: { ...authHeader(), 'Content-Type': 'application/json' }
	}

	return fetch(
		`${config.apiUrl}/users/${idUser}/collections/paginate/${page}/${perPage}`,
		requestOptions
	).then(handleResponse)
}

function getCollectionsByTypeAndUser(
	idUser,
	collectionType,
	page,
	perPage,
	sortColumn,
	sortDirection,
	search
	// onlyPublished
) {
	const requestOptions = {
		method: 'GET',
		headers: { ...authHeader(), 'Content-Type': 'application/json' }
	}

	// const contentPath = onlyPublished
	// 	? `published_${collectionType}`
	// 	: collectionType
	let url = `${config.apiUrl}/users/${idUser}/collections/${collectionType}s/paginate/${page}/${perPage}/${sortColumn}/${sortDirection}`
	if (search) {
		url += `?q=${search}`
	}
	return fetch(url, requestOptions).then(handleResponse)
}

function setCover(collectionId, cover) {
	let formData = new FormData()
	formData.append('cover', cover)

	const requestOptions = {
		method: 'POST',
		headers: { ...authHeader() },
		body: formData
	}

	return fetch(
		`${config.apiUrl}/collections/${collectionId}/cover`,
		requestOptions
	).then(handleResponse)
}

function deleteCollection(collectionId) {
	const requestOptions = {
		method: 'DELETE',
		headers: { ...authHeader(), 'Content-Type': 'application/json' }
	}

	return fetch(
		`${config.apiUrl}/collections/${collectionId}`,
		requestOptions
	).then(handleResponse)
}

function getCollectionByUrl(url) {
	const requestOptions = {
		method: 'GET',
		headers: { ...authHeader(), 'Content-Type': 'application/json' }
	}

	return fetch(`${config.apiUrl}/collections/${url}`, requestOptions).then(
		handleResponse
	)
}

function sendCollectionToApproval(idSerie) {
	const requestOptions = {
		method: 'POST',
		headers: { ...authHeader(), 'Content-Type': 'application/json' }
	}

	return fetch(
		`${config.apiUrl}/collections/${idSerie}/to-approval`,
		requestOptions
	).then(handleResponse)
}

function getAllCollectionsToApproval(curators, page, perPage) {
	const requestOptions = {
		method: 'GET',
		headers: { ...authHeader(), 'Content-Type': 'application/json' }
	}

	if (curators) {
		return fetch(
			`${config.apiUrl}/collections/waiting-approval-curator/paginate/${page}/${perPage}`,
			requestOptions
		).then(handleResponse)
	} else {
		return fetch(
			`${config.apiUrl}/collections/waiting-approval-no-curator/paginate/${page}/${perPage}`,
			requestOptions
		).then(handleResponse)
	}
}

function getAllPendingCollectionsByType(collectionType, page, perPage) {
	const requestOptions = {
		method: 'GET',
		headers: { ...authHeader(), 'Content-Type': 'application/json' }
	}

	return fetch(
		`${config.apiUrl}/collections/waiting-approval/${collectionType}/paginate/${page}/${perPage}`,
		requestOptions
	).then(handleResponse)
}

function setCurator(collectionId, curator_id) {
	const requestOptions = {
		method: 'POST',
		headers: { ...authHeader(), 'Content-Type': 'application/json' },
		body: JSON.stringify({ curator_id })
	}

	return fetch(
		`${config.apiUrl}/collections/${collectionId}/curator`,
		requestOptions
	).then(handleResponse)
}

function publishCollection(collectionId) {
	const requestOptions = {
		method: 'POST',
		headers: { ...authHeader(), 'Content-Type': 'application/json' }
	}

	return fetch(
		`${config.apiUrl}/collections/${collectionId}/to-approval`,
		requestOptions
	).then(handleResponse)
}

function defineContent(collectionId, type, message) {
	const requestOptions = {
		method: 'POST',
		headers: { ...authHeader(), 'Content-Type': 'application/json' },
		body: JSON.stringify({ message })
	}

	return fetch(
		`${config.apiUrl}/collections/${collectionId}/${type}`,
		requestOptions
	).then(handleResponse)
}

function actionContent(action, collectionId) {
	const requestOptions = {
		method: 'POST',
		headers: { ...authHeader(), 'Content-Type': 'application/json' }
	}

	return fetch(
		`${config.apiUrl}/collections/${collectionId}/${action}`,
		requestOptions
	).then(handleResponse)
}
