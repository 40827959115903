import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { Button, Pagination } from 'semantic-ui-react'

import { contentActions } from '../../../app/_actions'
// import noImg from '../../../assets/no-image.jpg'

import * as ListStyle from '../../../pages/Dashboard/Content/List/styles.js'

import PageHeader from '../PageHeader'
import { Alert, Divider } from '@mui/material'
// import LandingPageContentBlock from '../../PublicPages/LandingPageContentBlock'
import i18n from '../../../i18n'
import { ContentBlock } from '../../../eita-react-components'

const PER_PAGE = 5
function MyFavorites({ title = 'Minha lista', showMore, header = null }) {
	const dispatch = useDispatch()
	// @ts-ignore
	const { favorite } = useSelector((state) => state.content)
	const [page, setPage] = useState(1)

	const onBlockClick = (item) => {
		if (item.collection_type.id) {
			window.open(
				`/${item.collection_type.slug}/${item.friendly_url}`,
				'_blank'
			)
			return
		}

		if (i18n.language == 'es-ES') {
			window.open(`/contenido/${item.friendly_url}`, '_blank')
			return
		}

		window.open(`/conteudo/${item.friendly_url}`, '_blank')
	}

	useEffect(() => {
		dispatch(contentActions.getUserFavorites(page, PER_PAGE))
	}, [dispatch, page])

	return (
		<>
			{header || <PageHeader title={title} />}
			{favorite && favorite.content ? (
				<ListStyle.Wrapper>
					{favorite && favorite.content.length > 0 ? (
						favorite.content.map((item, idx) => {
							const genericType = item.content_type.id
								? item.content_type
								: item.collection_type
							const key = item.content_type.id
								? `content_${item.id}`
								: `collection_${item.id}`
							return (
								<Fragment key={item.id}>
									<ContentBlock
										key={key}
										id={item.id}
										title={item.title}
										description={item.description}
										img={item.cover_id}
										onClick={() => onBlockClick(item)}
										contentOrCollectionType={genericType}
										hasFavoriteButton={true}
										is_favorited_by_current_user={true}
									/>
									{idx < favorite.content.length - 1 && (
										<Divider
											style={{
												width: '100%',
												borderColor:
													'rgba(0, 0, 0, 0.0)'
											}}
										/>
									)}
								</Fragment>
							)
						})
					) : (
						<Alert severity="info" sx={{ width: '100%' }}>
							Você não possui nenhum conteúdo favorito.{' '}
						</Alert>
					)}
					{showMore ? (
						<NavLink to="/painel/minha-conta/favoritos">
							<Button>Ver todos os favoritos</Button>
						</NavLink>
					) : (
						<Pagination
							defaultActivePage={page}
							totalPages={Math.ceil(
								favorite.totalResults / PER_PAGE
							)}
							onPageChange={(e, { activePage }) => {
								e.preventDefault()
								// @ts-ignore
								setPage(activePage)
							}}
						/>
					)}
				</ListStyle.Wrapper>
			) : (
				<h3>
					Você ainda não favoritou nenhum dos conteúdos disponíveis.
				</h3>
			)}
		</>
	)
}

export default MyFavorites
