import React from 'react'

import * as S from './styles'

function YouTubeViewer({ videoId, item }) {
	return (
		<S.VideoContainer>
			<iframe
				src={`https://www.youtube.com/embed/${videoId}?autoplay=0&rel=0`}
				title={item.title}
				allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
				allowFullScreen
				style={{ width: '100%', height: '100%' }}
			></iframe>
		</S.VideoContainer>
	)
}

export default YouTubeViewer
