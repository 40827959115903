import React from 'react'
import { withRouter } from 'react-router-dom'

import ManageCollectionsWrapper from '../../Collections/ManageCollectionsWrapper'
import { collectionsConstants } from '../../../../app/_constants'

const AllCollections = ({ collectionTypeParam }) => {
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				gap: '8px',
				paddingTop: '16px',
				overflow: 'hidden'
			}}
		>
			<ManageCollectionsWrapper
				isUserCollection={false}
				collectionTypeParam={collectionsConstants.COLLECTION_TYPES[
					collectionTypeParam
				].plural.toLowerCase()}
			/>
		</div>
	)
}

export default withRouter(AllCollections)
