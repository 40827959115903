import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Icon as SemanticIcon } from 'semantic-ui-react'
import { Alert, AlertTitle, Box, Tab, Tabs } from '@mui/material'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import 'react-pdf/dist/Page/AnnotationLayer.css'
import 'react-pdf/dist/Page/TextLayer.css'

import { contentActions, collectionsActions } from '../../../app/_actions'
import { fetchYouTubeCode } from '../../../utils/functions'
import * as S from './styles'

import ContentUserActions from '../ContentUserActions'
import ContentSheet from '../ContentSheet'
import PDFViewer from '../PDFViewer'
import SearchBox from '../SearchBox'
import history from '../../../app/history'
import theme from '../../../styles/theme'
import SharingBar from '../SharingBar'
import Icon from '../../Icon'
import CollectionSheet from '../CollectionSheet'
import ContentOrCollectionTypeIcon from '../../ContentOrCollectionTypeIcon'
import CollectionContentIndicationsList from '../CollectionContentIndicationsList'
import RichTextView from '../../RichText/RichTextView'
import YouTubeViewer from '../YouTubeViewer'

function ContentOrCollectionPage({ isCollection, isPreview }) {
	const dispatch = useDispatch()
	const [tabIndex, setTabIndexValue] = useState(0)
	const [isSharingBarVisible, setIsSharingBarVisible] = useState(false)
	// @ts-ignore
	const { object: content } = useSelector((state) => state.content)
	// @ts-ignore
	const { object: collection } = useSelector((state) => state.collections)
	// const isWpLoggedIn = document.getElementsByClassName('logged-in').length
	const [searchText, setSearchText] = useState('')
	const { t, i18n } = useTranslation()
	const typeName = !isCollection
		? 'Conteúdo'
		: collection.collection_type
		? collection.collection_type.name
		: ''
	const typeGenre = !isCollection
		? 'o'
		: collection.collection_type
		? collection.collection_type.genre
		: ''

	const { url } = useParams()

	const isMobile = window.innerWidth <= 782

	useEffect(() => {
		try {
			if (url) {
				if (isCollection) {
					dispatch(collectionsActions.getCollectionByUrl(url))
					return
				}

				dispatch(contentActions.getContentByUrl(url))
			}
		} catch {
			console.error(`Erro ao recuperar ${typeName} pelo link`)
		}
	}, [dispatch, url, isCollection, typeName])

	const handleTabChange = (_, newValue) => {
		setTabIndexValue(newValue)
	}

	function handleChange({ target }) {
		setSearchText(target.value)
	}

	const handleFilter = useCallback(() => {
		if (i18n.language === 'es-ES') {
			history.push({
				pathname: '/resultado-busqueda?busca=' + searchText
			})
		} else {
			history.push({
				pathname: '/resultado-busca?busca=' + searchText
			})
		}
		window.location.reload()
	}, [searchText, i18n.language])

	/* Ativa filtro ao dar "Enter" no input de texto */
	function handleKeyDown(event) {
		if (event.key === 'Enter') {
			handleFilter()
		}
	}

	function a11yProps(index) {
		return {
			id: `simple-tab-${index}`,
			'aria-controls': `simple-tabpanel-${index}`
		}
	}

	const onCollectionContentClick = (friendlyUrl) => {
		const win =
			i18n.language == 'es-ES'
				? window.open(`/contenido/${friendlyUrl}`, '_blank')
				: window.open(`/conteudo/${friendlyUrl}`, '_blank')
		if (win != null) {
			win.focus()
		}
	}

	function CustomTabPanel(props) {
		const { children, value, index, ...other } = props

		return (
			<div
				role="tabpanel"
				hidden={value !== index}
				id={`simple-tabpanel-${index}`}
				aria-labelledby={`simple-tab-${index}`}
				{...other}
			>
				{value === index && children}
			</div>
		)
	}

	const item = !isCollection ? content : collection

	if (!item || !item.id) {
		return <></>
	}

	const itemTitle = item.title
	const itemDescription = item.description
	const isPDF = item.file_id && item.file_content_type === 'application/pdf'
	const youTubeCode = fetchYouTubeCode(item)

	return (
		<div className="page">
			{!isPreview && (
				<S.SearchBoxWrapp>
					<SearchBox
						isHome={false}
						value={searchText}
						handleChange={handleChange}
						handleFilter={handleFilter}
						handleKeyDown={handleKeyDown}
						autoFocus={false}
					/>
				</S.SearchBoxWrapp>
			)}
			<S.Wraper>
				{isPreview && (
					<Alert variant="filled" severity="warning">
						<AlertTitle>
							{t('content-page.preview', 'Pré-visualização')}
						</AlertTitle>
						{t(
							'content-page.preview-message',
							`${typeGenre.toUpperCase()} ${typeName.toLowerCase()} que você está visualizando ainda não foi publicad${typeGenre} na Biblioteca Proteja. Envie ${typeGenre} ${typeName.toLowerCase()} para aprovação para que ${
								typeGenre === 'a' ? 'ela' : 'ele'
							} se torne visível ao público do Portal.`
						)}
					</Alert>
				)}
				{item ? (
					<S.ContentWraper>
						<S.Main>
							<S.Title>{itemTitle}</S.Title>
							{!isCollection && (
								<>
									<S.Info>
										<S.InfoWrapper>
											<S.InfoTitle>
												{t(
													'content-page.author',
													'Autoria:'
												)}{' '}
											</S.InfoTitle>
											<S.InfoText>
												{item.author
													? item.author.replace(
															/[,]/g,
															', '
													  )
													: ''}
											</S.InfoText>
										</S.InfoWrapper>
									</S.Info>
									{item.collection &&
										item.collection.map((collection) => (
											<S.Info
												key={`collection_${collection.id}`}
											>
												<S.InfoWrapper>
													<S.InfoTitle>
														{
															collection
																.collection_type
																.name
														}
														:
													</S.InfoTitle>
													<S.InfoText>
														<S.InfoTextLink
															href={`/${collection.collection_type.slug}/${collection.friendly_url}`}
														>
															&nbsp;
															{collection.title}
														</S.InfoTextLink>
													</S.InfoText>
												</S.InfoWrapper>
											</S.Info>
										))}
								</>
							)}
							<S.UserActionsContainer>
								{!isCollection && (
									<S.ContentButtonsContainer>
										{item.file_id && (
											<S.DownloadButton
												href={
													process.env.REACT_APP_API.split(
														'api'
													)[0] + item.file_id
												}
											>
												<SemanticIcon
													name="download"
													style={{
														color: '#fff',
														fontSize: '16px'
													}}
												/>
												{t(
													'content-page.download',
													'Baixar'
												)}
											</S.DownloadButton>
										)}
										{item.content_link && (
											<S.LinkButton
												target="_blank"
												href={item.content_link}
											>
												{t(
													'content-page.external-link',
													'Link externo'
												)}
											</S.LinkButton>
										)}
									</S.ContentButtonsContainer>
								)}
								<S.UserActions className="user-actions">
									<S.SharingButton
										onClick={() =>
											setIsSharingBarVisible(
												(prevState) => !prevState
											)
										}
									>
										<Icon
											type="far fa-regular fa-paper-plane"
											style={{
												color: theme.colors.primary,
												fontSize: '16px'
											}}
										/>
										{t(
											'content-page.share',
											'Compartilhar'
										)}
									</S.SharingButton>
									<ContentUserActions
										id={item.id}
										url={item.friendly_url}
										item={item}
										onClose={null}
										isCollection={isCollection}
									/>
								</S.UserActions>
							</S.UserActionsContainer>
							<S.SharingBar
								className={isSharingBarVisible && 'clicked'}
							>
								<SharingBar item={item} />
							</S.SharingBar>

							<S.Short>
								<RichTextView markdown={itemDescription} />
							</S.Short>
							{item.themes && (
								<S.Themes>
									{item.themes.map((t) => (
										<S.ThemeChip
											key={t.id}
											href={
												i18n.language == 'es-ES'
													? '/resultado-busqueda?theme_id[]=' +
													  t.id
													: '/resultado-busca?theme_id[]=' +
													  t.id
											}
										>
											{t.name}
										</S.ThemeChip>
									))}
								</S.Themes>
							)}
							{isCollection && (
								<CollectionContentIndicationsList
									item={item}
									onCollectionContentClick={
										onCollectionContentClick
									}
								/>
							)}
							<Box sx={{ width: '100%' }}>
								<Box sx={{ width: '100%' }}>
									<Box
										sx={{
											borderBottom: 1,
											borderColor: 'divider'
										}}
									>
										<S.TabsContainer>
											<Tabs
												value={tabIndex}
												onChange={handleTabChange}
												aria-label="basic tabs example"
												textColor="primary"
												indicatorColor="secondary"
											>
												{(isPDF ||
													(!!youTubeCode &&
														!isMobile)) && (
													<Tab
														label={t(
															'content-page.preview-tab',
															'PRÉ-VISUALIZAÇÃO'
														)}
														{...a11yProps(0)}
													/>
												)}
												<Tab
													label={t(
														'content-page.spec-sheet',
														'FICHA TÉCNICA'
													)}
													{...a11yProps(1)}
												/>
											</Tabs>
										</S.TabsContainer>
									</Box>
									{!isCollection &&
										(isPDF ||
											(!!youTubeCode && !isMobile)) && (
											<CustomTabPanel
												value={tabIndex}
												index={0}
											>
												{isPDF && (
													<PDFViewer
														fileId={item.file_id}
														fileContentType={
															item.file_content_type
														}
													/>
												)}
												{!!youTubeCode && !isMobile && (
													<YouTubeViewer
														videoId={youTubeCode}
														item={item}
													/>
												)}
											</CustomTabPanel>
										)}
									<CustomTabPanel
										value={tabIndex}
										index={
											!isCollection &&
											(isPDF ||
												(!!youTubeCode && !isMobile))
												? 1
												: 0
										}
									>
										{isCollection ? (
											<CollectionSheet item={item} />
										) : (
											<ContentSheet item={item} />
										)}
									</CustomTabPanel>
								</Box>
							</Box>
						</S.Main>
						<S.SideInfo>
							<S.Block className="img">
								<S.Icon>
									<ContentOrCollectionTypeIcon
										id={
											isCollection
												? item.collection_type.id
												: item.content_type.id
										}
									/>
									<S.Type className="content-type">
										{isCollection
											? 'Série'
											: item.content_type.name}
									</S.Type>
								</S.Icon>
								{youTubeCode ? (
									<>
										<S.MobileCover>
											<YouTubeViewer
												videoId={youTubeCode}
												item={item}
											/>
										</S.MobileCover>
										<S.DesktopCover>
											<img
												alt={itemTitle}
												src={item.cover_id + '.thumb'}
											/>
										</S.DesktopCover>
									</>
								) : (
									<img
										alt={itemTitle}
										src={item.cover_id + '.thumb'}
									/>
								)}
							</S.Block>
						</S.SideInfo>
					</S.ContentWraper>
				) : (
					<>
						{/* <h1>Nenhum{typeGenre === 'a' ? 'a' : ''} {typeName} encontrado!</h1>
					<Link to="/">Voltar para a página inicial</Link> */}
					</>
				)}
			</S.Wraper>
		</div>
	)
}

export default ContentOrCollectionPage
