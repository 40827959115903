import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ContentToApproval from '../ContentToApproval'
import CollectionToApproval from '../CollectionToApproval'
import { collectionTypeList } from '../../../../app/_constants'
import * as ManageContentWrapperStyles from '../../../../pages/Dashboard/Content/ManageContentWrapper/styles.js'
import { Tab, Tabs } from '@mui/material'
import CustomTabPanel from '../../CustomTabPanel'
import PageHeader from '../../PageHeader'
import ErrorIcon from '@mui/icons-material/Error'
import { collectionsActions } from '../../../../app/_actions'

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`
	}
}

function CurationTable({ header = null }) {
	const dispatch = useDispatch()

	// @ts-ignore
	const { user } = useSelector((state) => state.authentication)
	const [tabIndexValue, setTabIndexValue] = useState(0)

	const {
		data
		// @ts-ignore
	} = useSelector((state) => state.content)
	// @ts-ignore
	const state = useSelector((state) => state.collections)

	const getCollectionData = useCallback(
		(collectionTypeObject) => {
			dispatch(
				collectionsActions.getAllPendingCollectionsByType(
					1,
					1,
					collectionTypeObject.slug
				)
			)
		},
		[dispatch]
	)

	useEffect(() => {
		collectionTypeList.map((collectionTypeObject) => {
			getCollectionData(collectionTypeObject)
		})
	}, [getCollectionData])

	const handleTabChange = useCallback((_, newValue) => {
		setTabIndexValue(newValue)
	}, [])

	const isCuratorChief = user.roles.includes('ROLE_CURADOR_CHEFE')
	const isCurator = user.roles.includes('ROLE_CURADOR')
	const isAdmin = user.roles.includes('ROLE_ADMIN')

	return (
		<>
			{(isAdmin || isCuratorChief) && (
				<>
					{header || <PageHeader title="Pendentes de aprovação" />}

					<ManageContentWrapperStyles.TabsContainer>
						<Tabs
							value={tabIndexValue}
							onChange={handleTabChange}
							indicatorColor="secondary"
						>
							<Tab
								label="Conteúdos"
								{...a11yProps(0)}
								icon={
									data && data.totalResults > 0 ? (
										<ErrorIcon color="secondary" />
									) : (
										<></>
									)
								}
								iconPosition="end"
							/>
							{collectionTypeList.map(
								(collectionTypeObject, idx) => (
									<Tab
										key={idx}
										label={collectionTypeObject.plural}
										{...a11yProps(idx + 1)}
										icon={
											state[
												`${collectionTypeObject.slug}s`
											] &&
											state[
												`${collectionTypeObject.slug}s`
											].totalResults > 0 ? (
												<ErrorIcon color="secondary" />
											) : (
												<></>
											)
										}
										iconPosition="end"
									/>
								)
							)}
						</Tabs>
					</ManageContentWrapperStyles.TabsContainer>
					<CustomTabPanel value={tabIndexValue} index={0}>
						<ContentToApproval
							isAdmin={isAdmin}
							isCuratorChief={isCuratorChief}
							isCurator={isCurator}
						/>
					</CustomTabPanel>
					{collectionTypeList.map((collectionTypeObject, idx) => (
						<CustomTabPanel
							value={tabIndexValue}
							index={idx + 1}
							key={collectionTypeObject.slug}
						>
							<React.Fragment>
								<CollectionToApproval
									isAdmin={isAdmin}
									isCuratorChief={isCuratorChief}
									isCurator={isCurator}
									collectionTypeObject={collectionTypeObject}
								/>
							</React.Fragment>
						</CustomTabPanel>
					))}
				</>
			)}
		</>
	)
}

export default CurationTable
