import React from 'react'
import Markdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'

const RichTextView = ({ markdown }) => {
	return (
		<Markdown className="richText" rehypePlugins={[rehypeRaw]}>
			{markdown}
		</Markdown>
	)
}

export default RichTextView
