import React, { useState } from 'react'
import {
	Button,
	Pagination,
	Table,
	Checkbox,
	Popup,
	Confirm
} from 'semantic-ui-react'

import ActionColumnTable from './ActionColumnTable'
import FormatCellValue from './FormatCellValue'
import LoadingDimmer from './LoadingDimmer'

function DataTable({
	checkbox = false,
	prefixKey = 'dataTable_',
	columns,
	loading = false,
	data,
	totalResults = null,
	perPage = 5,
	page = null,
	setPage = null,
	// checkStatus = false,
	onEdit = null,
	onDelete = null,
	deleteTitle = null,
	deleteMessage = null,
	onPublish = null,
	onApprove = null,
	onReject = null,
	setCurator = null
	// size = 'large'
}) {
	const [openConfirm, setOpenConfirm] = useState(false)
	const [selectedItem, setSelectedItem] = useState(null)

	const hasAnyAction =
		onEdit || onDelete || onPublish || setCurator || onApprove || onReject

	return (
		<>
			<Table
				celled
				compact={'very'}
				definition={checkbox}
				stackable
				striped
				columns={8}
				// size={size}
			>
				<Table.Header fullWidth>
					<Table.Row>
						{checkbox && <Table.HeaderCell />}
						{columns.map((column) => (
							<Table.HeaderCell
								key={`${prefixKey}${column.name}`}
							>
								{column.label}
							</Table.HeaderCell>
						))}
						{hasAnyAction && (
							<Table.HeaderCell>Ações</Table.HeaderCell>
						)}
					</Table.Row>
				</Table.Header>

				{loading ? (
					<LoadingDimmer />
				) : (
					<Table.Body>
						{data &&
							data.map((item) => {
								return (
									<Table.Row
										key={`${prefixKey}${item.id}`}
										style={{
											backgroundColor:
												item.status === 1
													? 'lightgreen' // A background color for "Aprovado" status
													: item.status === 2
													? 'lightcoral' // A background color for "Rejeitado" status
													: item.status === 3
													? 'lightyellow' // A background color for "Solicitada Correção" status
													: item.status === 0
													? 'lightblue' // A background color for "Aguardando Aprovação" status
													: 'white' // Default background color
										}}
									>
										{checkbox && (
											<Table.Cell collapsing>
												<Checkbox />
											</Table.Cell>
										)}
										{columns.map((column) => {
											if (column.info) {
												const statusValue = item.status
												let infoStatusText = ''
												if (statusValue === 1) {
													infoStatusText =
														item[
															column
																.textInfoColumn
														]
												} else if (statusValue === 2) {
													infoStatusText =
														item[
															column
																.textInfoColumn2
														]
												} else if (statusValue === 3) {
													infoStatusText =
														item[
															column
																.textInfoColumn3
														]
												}
												return (
													<Popup
														disabled={
															!item.status ||
															!infoStatusText
														}
														size="huge"
														content={infoStatusText}
														key={`${prefixKey}row_${column.name}`}
														trigger={
															<Table.Cell
																// positive={
																// 	column.status &&
																// 	item[
																// 		'status'
																// 	] === 1
																// }
																// warning={
																// 	column.status &&
																// 	(item[
																// 		'status'
																// 	] === 0 ||
																// 		item[
																// 			'status'
																// 		] === 3)
																// }
																// negative={
																// 	column.status &&
																// 	item[
																// 		'status'
																// 	] === 2
																// }
																width={
																	column.width
																}
															>
																<FormatCellValue
																	value={
																		item[
																			column
																				.name
																		]
																	}
																	format={
																		column.format
																	}
																	status={
																		column.status
																	}
																/>
															</Table.Cell>
														}
													/>
												)
											} else if (
												column.name === 'user' ||
												column.name === 'curator'
											) {
												if (
													!column.name ||
													!item[column.name] ||
													!item[column.name].name
												) {
													return (
														<Table.Cell
															key={`${prefixKey}row_${column.name}_${item.id}`}
														></Table.Cell>
													)
												}
												const {
													name,
													email,
													organization
												} = item[column.name]
												const contactInfo = `${name} - ${email} - ${organization.name}`
												return (
													<Table.Cell
														key={`${prefixKey}row_${column.name}_${item.id}`}
													>
														<FormatCellValue
															value={contactInfo}
															customRender={() =>
																column.customRender(
																	contactInfo,
																	email
																)
															}
														></FormatCellValue>
													</Table.Cell>
												)
											} else {
												return (
													<Table.Cell
														key={`${prefixKey}row_${column.name}`}
														width={column.width}
													>
														<FormatCellValue
															value={
																item[
																	column.name
																]
															}
															format={
																column.format
															}
															status={
																column.status
															}
															customRender={
																column.customRender
															}
														/>
													</Table.Cell>
												)
											}
										})}
										{hasAnyAction && (
											<Table.Cell
												key={`${prefixKey}row_$action_column_table_${item.id}`}
											>
												<ActionColumnTable
													item={item}
													onEdit={onEdit}
													onPublish={onPublish}
													onApprove={onApprove}
													onReject={onReject}
													onDelete={onDelete}
													setSelectedItem={
														setSelectedItem
													}
													setOpenConfirm={
														setOpenConfirm
													}
													setCurator={setCurator}
													// checkStatus={checkStatus}
												/>
											</Table.Cell>
										)}
									</Table.Row>
								)
							})}
					</Table.Body>
				)}

				{data && totalResults && page && perPage ? (
					<Table.Footer fullWidth>
						<Table.Row>
							{checkbox && <Table.HeaderCell />}
							<Table.HeaderCell colSpan={columns.length + 1}>
								{checkbox && (
									<Button size="small" disabled>
										Excluir selecionados
									</Button>
								)}
								<div
									style={{
										display: 'inline-flex',
										float: 'right'
									}}
								>
									<Pagination
										boundaryRange={1}
										siblingRange={0}
										firstItem={null}
										lastItem={null}
										defaultActivePage={page}
										totalPages={Math.ceil(
											totalResults / perPage
										)}
										onPageChange={(e, { activePage }) => {
											e.preventDefault()
											setPage(activePage)
										}}
									/>
								</div>
							</Table.HeaderCell>
						</Table.Row>
					</Table.Footer>
				) : null}
			</Table>
			<Confirm
				open={openConfirm}
				onCancel={() => setOpenConfirm(false)}
				header={deleteTitle}
				cancelButton="Cancelar"
				confirmButton="Confirmar"
				content={deleteMessage}
				size="large"
				onConfirm={() => {
					onDelete(selectedItem)
					setOpenConfirm(false)
				}}
			/>
		</>
	)
}

export default DataTable
