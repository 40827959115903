import React from 'react'
import Button from '@mui/material/Button'
import { useDispatch } from 'react-redux'

import { truncate } from '../../../utils/functions'
import * as S from './styles'

import ContentOrCollectionTypeIcon from '../../ContentOrCollectionTypeIcon'
import { contentActions } from '../../../app/_actions'
import Icon from '../../Icon'

/**
 * @function ContentBlock
 */
const LandingPageContentBlock = ({
	id,
	img,
	title,
	description,
	onClick,
	contentType,
	is_favorited_by_current_user = false,
	hasFavoriteButton = false
}) => {
	const dispatch = useDispatch()

	const actions = {
		favorite: {
			icon: 'far fa-bookmark',
			title: 'Salvar',
			action: 'favorite',
			activeIcon: 'fas fa-bookmark',
			key: 'is_favorited_by_current_user',
			active: is_favorited_by_current_user,
			tip: is_favorited_by_current_user
				? 'Excluir dos favoritos'
				: 'favoritar'
		}
	}

	const handleAction = (type, active) => {
		if (active) {
			dispatch(contentActions.actionContent('unfavorite', id))
			return
		}

		dispatch(contentActions.actionContent(type, id))
	}
	return (
		<S.Wrapper>
			<S.Block className="img" onClick={onClick}>
				<img alt={title} src={img} />
				{contentType.id && (
					<S.Icon>
						<ContentOrCollectionTypeIcon id={contentType.id} />
						<S.Type className="content-type">
							{contentType.name}
						</S.Type>
					</S.Icon>
				)}
			</S.Block>
			<S.ContentInfoContainer>
				<S.TitleWrapper>
					<S.Title onClick={onClick}>{truncate(title, 100)}</S.Title>
					{hasFavoriteButton && (
						<Button
							key={actions.favorite.action}
							data-tip={actions.favorite.tip}
							title={actions.favorite.tip}
							onClick={() =>
								handleAction(
									actions.favorite.action,
									actions.favorite.active
									// actions.favorite.counterType,
									// actions.favorite.key,
									// actions.favorite.counterKey,
									// item
								)
							}
							startIcon={
								<Icon
									type={
										actions.favorite.active
											? actions.favorite.activeIcon
											: actions.favorite.icon
									}
									data-tip={actions.favorite.tip}
									id={actions.favorite.icon}
								/>
							}
						/>
					)}
				</S.TitleWrapper>
				<S.Description>{truncate(description, 290)}</S.Description>
				{/* <S.KeywordsContainer>
					{keywords &&
						keywords
							.split(', ')
							.map((keyword, idx) => (
								<S.Keyword key={idx}>{keyword}</S.Keyword>
							))}
				</S.KeywordsContainer> */}
			</S.ContentInfoContainer>
		</S.Wrapper>
	)
}

export default LandingPageContentBlock
