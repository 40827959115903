import styled, { css } from 'styled-components'

export const DocumentWrapper = styled('div').attrs((props) => ({
	isExpanded: props.isExpanded
}))`
	position: relative;
	${(props) =>
		props.isExpanded &&
		css`
			position: fixed;
			top: 0;
			left: 0;
			width: 100vw;
			height: 100vh;
			z-index: 99999999;
		`}
`
export const DocumentContainer = styled('div').attrs((props) => ({
	isExpanded: props.isExpanded
}))`
	margin-top: 16px;
	height: 400px;
	overflow: auto;
	background-color: rgba(200, 200, 200, 1);
	${(props) =>
		props.isExpanded &&
		css`
			height: 100vh;
			border: none;
			margin: 0;
			background-color: rgba(200, 200, 200, 0.75);
		`}

	.react-pdf__Page {
		display: flex;
		justify-content: center;
		padding: 16px 0px !important;
		background-color: transparent !important;
		${(props) => (props.isExpanded ? css`` : css``)}
		margin: 0 16px;
		.react-pdf__Page__canvas {
			box-shadow: 0px 4px 15px 2px rgba(0, 0, 0, 0.35);
		}
	}
	.react-pdf__Page__annotations.annotationLayer,
	.react-pdf__Page__textContent.textLayer {
		height: auto !important;
		width: auto !important;
	}

	@media screen and (max-width: 1050px) {
		.react-pdf__Page__canvas {
			width: 100% !important;
			height: 100% !important;
		}
	}
`

export const PaginationContainer = styled('div').attrs((props) => ({
	isExpanded: props.isExpanded
}))`
	display: flex;
	width: 100%;
	justify-content: center;
	gap: 16px;
	align-items: center;
	${(props) => props.isExpanded && css``}
`

export const PdfPaginationButton = styled.div`
	:hover {
		cursor: pointer;
	}
`
