import React from 'react'

import * as S from '../styles'
import { cleanMarkdown, truncate } from '../../../utils/functions'
import { useTranslation } from 'react-i18next'

const ContentInfo = ({ item }) => {
	const { t, i18n } = useTranslation()
	return (
		<S.Overview>
			<S.Title>{item.title}</S.Title>
			<S.Info>
				<S.InfoWrapper>
					<S.InfoTitle>
						{t('content-info.author', 'Autoria:')}{' '}
					</S.InfoTitle>
					<S.InfoText>
						{item.author ? item.author.replace(/[,]/g, ', ') : ''}
					</S.InfoText>
				</S.InfoWrapper>
			</S.Info>
			<S.Short>{truncate(cleanMarkdown(item.description), 525)}</S.Short>
			{item.themes && (
				<S.Themes>
					{item.themes.map((t, idx) => (
						<S.ThemeChip
							href={
								i18n.language == 'es-ES'
									? '/resultado-busqueda?theme_id[]=' + t.id
									: '/resultado-busca?theme_id[]=' + t.id
							}
							key={idx}
						>
							{t.name}
						</S.ThemeChip>
					))}
				</S.Themes>
			)}
		</S.Overview>
	)
}

export default ContentInfo
