import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Loader, Dimmer, Image } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import PhoneInput from 'react-phone-number-input'
import ptBr from 'react-phone-number-input/locale/pt-BR.json'

import * as S from './styles'
import * as ManageContentWrapperStyles from '../../../pages/Dashboard/Content/ManageContentWrapper/styles.js'

import { orgsActions, userActions } from '../../../app/_actions'
import { isTmpId } from '../../../utils/functions'
import Icon from '../../Icon'
import AutocompleteEditable from '../Autocomplete/AutocompleteEditable'
import PageHeader from '../PageHeader'
import { Button, InputLabel, Tab, Tabs, TextField } from '@mui/material'
import CustomTabPanel from '../CustomTabPanel'
import EditPassword from '../EditPassword'

const fields = [
	{
		type: 'email',
		name: 'email',
		label: 'E-mail',
		icon: 'mail',
		placeholder: 'Informe seu e-mail',
		required: false,
		disabled: true
	},
	{
		type: 'text',
		name: 'name',
		label: 'Nome',
		icon: 'user',
		placeholder: 'Informe seu nome',
		required: true,
		disabled: false
	},
	{
		name: 'phone',
		type: 'phone',
		label: 'Telefone',
		placeholder: 'Informe seu telefone',
		required: true,
		disabled: false
	}
]

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`
	}
}

const MyAccount = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const [tabIndexValue, setTabIndexValue] = useState(0)
	const handleTabChange = useCallback((_, newValue) => {
		setTabIndexValue(newValue)
	}, [])
	const fileInput = React.useRef(null)

	// @ts-ignore
	const { user } = useSelector((state) => state.users)
	// @ts-ignore
	const { user: authUser } = useSelector((state) => state.authentication)

	// @ts-ignore
	const { orgs, fetchingOrgs } = useSelector((state) => state.orgs)

	const [inputs, setInputs] = useState(null)

	function submitUser() {
		const { name, organization, email, phone } = inputs
		dispatch(
			userActions.updateUser(user.id, {
				name,
				email,
				phone,
				organization_id: organization.id
			})
		)
	}

	function handleChange({ target }) {
		const { name: fieldKey, value } = target
		setInputs((inputs) => ({ ...inputs, [fieldKey]: value }))
	}

	const onFileChange = async (e) => {
		if (e.target.files && e.target.files.length > 0) {
			const file = e.target.files[0]
			dispatch(userActions.changePhoto(file))
		}
	}

	const onChangeOrg = ({ target }) => {
		const { name: fieldKey, value: newValueRaw } = target
		if (!newValueRaw || !isTmpId(newValueRaw.id)) {
			setInputs((inputs) => ({ ...inputs, [fieldKey]: newValueRaw }))
			return
		}

		dispatch(
			orgsActions.create({ name: newValueRaw.name }, (newValue) => {
				setInputs((inputs) => ({ ...inputs, [fieldKey]: newValue }))
			})
		)
	}

	useEffect(() => {
		if (!orgs || orgs.length === 0) {
			dispatch(orgsActions.getAll())
		}
	}, [dispatch, orgs])

	useEffect(() => {
		if (authUser) {
			dispatch(userActions.getUser(authUser.id))
		}
	}, [dispatch, authUser])

	useEffect(() => {
		if (user) {
			setInputs({
				name: user.name,
				organization: user.organization,
				email: user.email
			})
		}
	}, [user])

	if (!inputs) {
		return (
			<Dimmer>
				<Loader>Carregando...</Loader>
			</Dimmer>
		)
	}

	return (
		<>
			<PageHeader title="Minha conta" />
			{user && (
				<>
					<ManageContentWrapperStyles.TabsContainer>
						<Tabs
							value={tabIndexValue}
							onChange={handleTabChange}
							indicatorColor="secondary"
						>
							<Tab label="Editar perfil" {...a11yProps(0)} />
							<Tab label="Alterar senha" {...a11yProps(1)} />
						</Tabs>
					</ManageContentWrapperStyles.TabsContainer>
					<CustomTabPanel value={tabIndexValue} index={0}>
						<S.Background>
							<S.Wrapper>
								<S.AvatarInfo>
									{user.photo_id ? (
										<>
											<Image
												style={{
													height: 144,
													width: 138,
													borderRadius: '4px',
													border: '1px solid rgba(0, 0, 0, 0.20)',
													objectFit: 'cover'
												}}
												src={user.photo_id}
												size="small"
											/>
										</>
									) : (
										<Icon
											type="fa fa-user"
											style={{
												fontSize: '100px',
												margin: '20px 0'
											}}
										/>
									)}

									<S.PictureButtonWrapper>
										<Button
											variant="contained"
											onClick={() =>
												fileInput.current
													? fileInput.current.click()
													: null
											}
											size="medium"
											color="shadowButton"
											disableElevation
										>
											Escolher foto...
										</Button>
										<input
											type="file"
											id="file"
											accept="image/*"
											ref={fileInput}
											onChange={onFileChange}
											style={{ display: 'none' }}
										/>
										{/* <Typography variant="body2">
											Selecione uma imagem de até 150px{' '}
										</Typography> */}
									</S.PictureButtonWrapper>
								</S.AvatarInfo>
								<>
									{fields.map((field, key) => {
										return field.type != 'phone' ? (
											<S.InputWrapper key={key}>
												<InputLabel>
													{`${field.label} ${
														field.required
															? '*'
															: ''
													}`}
												</InputLabel>
												<TextField
													type={field.type}
													error={
														field.required &&
														!inputs[field.name]
													}
													fullWidth
													disabled={field.disabled}
													name={field.name}
													value={inputs[field.name]}
													placeholder={
														field.placeholder
													}
													onChange={handleChange}
												/>
											</S.InputWrapper>
										) : (
											<S.InputWrapper key={key}>
												<InputLabel>
													{field.label}
												</InputLabel>
												<PhoneInput
													key={key}
													defaultCountry="BR"
													labels={ptBr}
													style={{ width: '100%' }}
													placeholder={
														field.placeholder
													}
													value={user[field.name]}
													onChange={(value) => {
														handleChange({
															target: {
																name: field.name,
																value
															}
														})
													}}
												/>
											</S.InputWrapper>
										)
									})}
									<AutocompleteEditable
										multiple={false}
										label={t(
											'dashboard.organizations',
											'Organização ou instituição'
										)}
										fieldKey="organization"
										placeholder={t(
											'dashboard.organization-placeholder',
											'Adicionar organização ou instituição'
										)}
										data={orgs}
										loading={fetchingOrgs}
										onChange={onChangeOrg}
										value={inputs.organization}
										allowCreateRelated={true}
									/>
									<div>
										<Button
											variant="contained"
											onClick={submitUser}
										>
											Salvar alterações
										</Button>
									</div>
								</>
							</S.Wrapper>
						</S.Background>
					</CustomTabPanel>
					<CustomTabPanel value={tabIndexValue} index={1}>
						<EditPassword />
					</CustomTabPanel>
				</>
			)}
		</>
	)
}

export default MyAccount
