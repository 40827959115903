import {
	contentsAndCollectionsConstants,
	collectionsConstants
} from '../_constants'

export function collections(
	state = {
		object: {},
		loading: false,
		data: null,
		page: 0,
		loadingImage: false
	},
	action
) {
	switch (action.type) {
		case collectionsConstants.USER_COLLECTION_REQUEST:
		case collectionsConstants.LOAD_MORE_REQUEST:
		case collectionsConstants.CREATE_REQUEST:
		case collectionsConstants.UPDATE_REQUEST:
		case collectionsConstants.DELETE_REQUEST:
		case collectionsConstants.REQUEST:
		case collectionsConstants.PUBLISH_REQUEST:
		case collectionsConstants.GET_BY_TYPE_REQUEST:
			return {
				...state,
				loading: true
			}
		case collectionsConstants.SUCCESS:
		case collectionsConstants.USER_COLLECTION_SUCCESS:
		case collectionsConstants.USER_SERIE_SUCCESS:
			return {
				...state,
				data: action.data,
				page: action.page,
				loading: false
			}
		case collectionsConstants.GET_BY_TYPE_SUCCESS:
			return {
				...state,
				[action.collectionTypeSlug]: action.data,
				page: action.page,
				loading: false
			}
		case collectionsConstants.LOAD_MORE_SUCCESS:
			return {
				...state,
				data: {
					...state.data,
					...action.data,
					collections: [
						...state.data.collections,
						...action.data.collections
					]
				},
				page: action.page,
				loading: false
			}
		case collectionsConstants.CREATE_SUCCESS:
		case collectionsConstants.UPDATE_SUCCESS:
		case collectionsConstants.PUBLISH_SUCCESS:
			return {
				...state,
				object: action.data,
				loading: false
			}
		case collectionsConstants.SUCCESS_URL: {
			return {
				...state,
				object: action.data,
				loading: false
			}
		}
		case contentsAndCollectionsConstants.ACTION_SUCCESS:
			return {
				...state,
				object: {
					...state.object,
					is_favorited_by_current_user:
						'favorited' in action.data
							? action.data.favorited
							: state.object.is_favorited_by_current_user,
					is_liked_by_current_user:
						'liked' in action.data
							? action.data.liked
							: 'disliked' in action.data &&
							  action.data.disliked === true
							? false
							: state.object.is_liked_by_current_user,
					is_disliked_by_current_user:
						'disliked' in action.data
							? action.data.disliked
							: 'liked' in action.data &&
							  action.data.liked === true
							? false
							: state.object.is_disliked_by_current_user,
					number_of_likes: action.data.number_of_likes || 0
				}
			}
		case collectionsConstants.CLEAN:
			return {
				...state,
				loading: false,
				object: {}
			}
		case collectionsConstants.SET_COVER_REQUEST:
			return {
				...state,
				loadingImage: true
			}
		case collectionsConstants.SET_COVER_SUCCESS:
			return {
				...state,
				loadingImage: false,
				object: {
					...state.object,
					cover_id: action.data.cover_url || null,
					cover_filename: action.data.cover_filename || null
				}
			}
		case collectionsConstants.SET_COVER_FAILURE:
			return {
				...state,
				loadingImage: false
			}
		case collectionsConstants.FAILURE:
		case collectionsConstants.USER_COLLECTION_FAILURE:
		case collectionsConstants.LOAD_MORE_FAILURE:
		case collectionsConstants.DELETE_SUCCESS:
		case collectionsConstants.REQUEST_URL:
		case collectionsConstants.FAILURE_URL:
		case collectionsConstants.DELETE_FAILURE:
		case collectionsConstants.CREATE_FAILURE:
		case collectionsConstants.UPDATE_FAILURE:
		case collectionsConstants.COLLECTION_ACTION_REQUEST:
		case collectionsConstants.COLLECTION_ACTION_FAILURE:
		case collectionsConstants.PUBLISH_FAILURE:
		case collectionsConstants.GET_BY_TYPE_FAILURE:
			return {
				...state,
				loading: false
			}
		default:
			return state
	}
}
