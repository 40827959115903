import { authHeader } from '../auth-header'
import { handleResponse } from '../handle-response'

const config = {
	apiUrl: process.env.REACT_APP_API
}

export const contentService = {
	getAllContent,
	getAllPendingContent,
	loadContentSelection,
	createContent,
	updateContent,
	publishContent,
	deleteContent,
	setTypeAndThemes,
	getContentById,
	getContentByIds,
	getAllContentByUser,
	getAllContentByType,
	getAllRecentContent,
	setFile,
	setCurator,
	defineContent,
	setCover,
	actionContent,
	getContentByUrl,
	postComment,
	getUserFavorites,
	putComment,
	deleteComment
}

function getAllContent(
	page,
	perPage,
	sortColumn,
	sortDirection,
	search = null
) {
	const requestOptions = {
		method: 'GET',
		headers: authHeader()
	}
	let url = `${config.apiUrl}/content/paginate/${page}/${perPage}/${sortColumn}/${sortDirection}`
	if (search) {
		url += `?q=${search}`
	}
	return fetch(url, requestOptions).then(handleResponse)
}

function getAllPendingContent(page, perPage) {
	const requestOptions = {
		method: 'GET',
		headers: authHeader()
	}

	return fetch(
		`${config.apiUrl}/content/waiting-approval/paginate/${page}/${perPage}`,
		requestOptions
	).then(handleResponse)
}

async function loadContentSelection(q, loadedOptions, { page }) {
	const requestOptions = {
		method: 'GET',
		headers: {
			...authHeader()
		}
	}
	const response = await fetch(
		`${config.apiUrl}/content/paginate/${page}/10/title/asc?q=${q}`,
		requestOptions
	)
	const responseJSON = await response.json()
	const hasMore = responseJSON.has_next

	return {
		options: responseJSON.content.map((o) => ({
			value: o.id,
			label: o.title
		})),
		hasMore,
		additional: {
			page: hasMore ? page + 1 : page
		}
	}
}

function createContent(content) {
	const requestOptions = {
		method: 'POST',
		headers: { ...authHeader(), 'Content-Type': 'application/json' },
		body: JSON.stringify(content)
	}

	return fetch(`${config.apiUrl}/content/`, requestOptions).then(
		handleResponse
	)
}

function updateContent(contentId, content) {
	/* Evita enviar string vazia em caso de inteiros */
	Object.keys(content).forEach((k) => {
		if (content[k] === '') content[k] = null
	})
	const requestOptions = {
		method: 'PUT',
		headers: { ...authHeader(), 'Content-Type': 'application/json' },
		body: JSON.stringify(content)
	}

	return fetch(`${config.apiUrl}/content/${contentId}`, requestOptions).then(
		handleResponse
	)
}

function deleteContent(contentId) {
	const requestOptions = {
		method: 'DELETE',
		headers: { ...authHeader(), 'Content-Type': 'application/json' }
	}

	return fetch(`${config.apiUrl}/content/${contentId}`, requestOptions).then(
		handleResponse
	)
}

function publishContent(id) {
	const requestOptions = {
		method: 'POST',
		headers: { ...authHeader(), 'Content-Type': 'application/json' }
	}

	return fetch(
		`${config.apiUrl}/content/${id}/to-approval`,
		requestOptions
	).then(handleResponse)
}

function setTypeAndThemes(contentId, content_type_id, themes) {
	const requestOptions = {
		method: 'POST',
		headers: { ...authHeader(), 'Content-Type': 'application/json' },
		body: JSON.stringify({ content_type_id, themes })
	}

	return fetch(
		`${config.apiUrl}/content/${contentId}/typeandtheme`,
		requestOptions
	).then(handleResponse)
}

function setFile(contentId, file) {
	let formData = new FormData()
	formData.append('file', file)

	const requestOptions = {
		method: 'POST',
		headers: { ...authHeader() },
		body: formData
	}

	return fetch(
		`${config.apiUrl}/content/${contentId}/file`,
		requestOptions
	).then(handleResponse)
}

function setCover(contentId, cover) {
	let formData = new FormData()
	formData.append('cover', cover)
	const requestOptions = {
		method: 'POST',
		headers: { ...authHeader() },
		body: formData
	}

	return fetch(
		`${config.apiUrl}/content/${contentId}/cover`,
		requestOptions
	).then(handleResponse)
}

function setCurator(contentId, curator_id) {
	const requestOptions = {
		method: 'POST',
		headers: { ...authHeader(), 'Content-Type': 'application/json' },
		body: JSON.stringify({ curator_id })
	}

	return fetch(
		`${config.apiUrl}/content/${contentId}/curator`,
		requestOptions
	).then(handleResponse)
}

function defineContent(contentId, type, message) {
	const requestOptions = {
		method: 'POST',
		headers: { ...authHeader(), 'Content-Type': 'application/json' },
		body: JSON.stringify({ message })
	}

	return fetch(
		`${config.apiUrl}/content/${contentId}/${type}`,
		requestOptions
	).then(handleResponse)
}

function getContentById(contentId) {
	const requestOptions = {
		method: 'GET',
		headers: { ...authHeader(), 'Content-Type': 'application/json' }
	}

	return fetch(`${config.apiUrl}/content/${contentId}`, requestOptions).then(
		handleResponse
	)
}

function getContentByIds(contentIds) {
	const requestOptions = {
		method: 'POST',
		headers: { ...authHeader(), 'Content-Type': 'application/json' },
		body: JSON.stringify({ content_ids: contentIds })
	}

	return fetch(
		`${config.apiUrl}/content/bulk_by_ids/1/1000`,
		requestOptions
	).then(handleResponse)
}

function getAllContentByUser(
	page,
	perPage,
	sortColumn,
	sortDirection,
	idUser,
	search = null,
	onlyPublished = false
) {
	const requestOptions = {
		method: 'GET'
	}
	const contentPath = onlyPublished ? 'published_content' : 'content'
	let url = `${config.apiUrl}/users/${idUser}/${contentPath}/paginate/${page}/${perPage}/${sortColumn}/${sortDirection}`
	if (search) {
		url += `?q=${search}`
	}
	return fetch(url, requestOptions).then(handleResponse)
}

function getAllContentByType(page, perPage, idType) {
	const requestOptions = {
		method: 'GET',
		headers: { ...authHeader(), 'Content-Type': 'application/json' }
	}

	return fetch(
		`${config.apiUrl}/contenttype/${idType}/content/paginate/${page}/${perPage}`,
		requestOptions
	).then(handleResponse)
}

function getAllRecentContent(page, perPage) {
	const requestOptions = {
		method: 'GET',
		headers: { ...authHeader(), 'Content-Type': 'application/json' }
	}

	return fetch(
		`${config.apiUrl}/content/recent/paginate/${page}/${perPage}`,
		requestOptions
	).then(handleResponse)
}

function actionContent(action, contentId) {
	const requestOptions = {
		method: 'POST',
		headers: { ...authHeader(), 'Content-Type': 'application/json' }
	}

	return fetch(
		`${config.apiUrl}/content/${contentId}/${action}`,
		requestOptions
	).then(handleResponse)
}

function getContentByUrl(url) {
	const requestOptions = {
		method: 'GET',
		headers: { ...authHeader() }
	}

	return fetch(`${config.apiUrl}/content/${url}`, requestOptions).then(
		handleResponse
	)
}

function postComment(contentId, comment) {
	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
			'Access-Control-Allow-Origin': '*'
		},
		body: JSON.stringify({
			comment: comment,
			content_indication_id: contentId
		})
	}

	return fetch(`${config.apiUrl}/comments/`, requestOptions).then(
		handleResponse
	)
}

function getUserFavorites(page, perPage) {
	const requestOptions = {
		method: 'GET',
		headers: { ...authHeader(), 'Content-Type': 'application/json' }
	}

	return fetch(
		`${config.apiUrl}/users/content/my-favorites/paginate/${page}/${perPage}`,
		requestOptions
	).then(handleResponse)
}

function putComment(contentId, comment) {
	const requestOptions = {
		method: 'PUT',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
			'Access-Control-Allow-Origin': '*'
		},
		body: JSON.stringify({
			comment: comment
		})
	}

	return fetch(`${config.apiUrl}/comments/${contentId}`, requestOptions).then(
		handleResponse
	)
}

function deleteComment(contentId) {
	const requestOptions = {
		method: 'DELETE',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json',
			'Access-Control-Allow-Origin': '*'
		}
	}

	return fetch(`${config.apiUrl}/comments/${contentId}`, requestOptions).then(
		handleResponse
	)
}
