import React, { useEffect, useRef, useState } from 'react'
import { Alert, InputLabel, TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'

import CropImage from '../../CropImage'
import { CustomCropper } from '../../CustomCropper'

import '@mdxeditor/editor/style.css'
import * as S from './styles'
import 'react-advanced-cropper/dist/style.css'
import './styles.css'
import RichTextField from '../../../RichText/RichTextField'

const EditCommonFields = ({
	collectionTypeObject = null,
	inputs,
	onChange,
	setImageSrc,
	imageSrc,
	setCroppedImage,
	currentCoverImage,
	messageError,
	setShowFooter
}) => {
	const [isEditing, setIsEditing] = useState(false)
	const cropper = useRef(null)
	const { t } = useTranslation()
	if (!collectionTypeObject) {
		collectionTypeObject = {
			genre: 'o',
			name: 'Conteúdo'
		}
	}

	const handleCropChange = (newCropper) => {
		cropper.current = newCropper
	}

	const handleCropComplete = () => {
		if (cropper.current) {
			setCroppedImage({
				filename: 'blob',
				canvas: cropper.current.getCanvas()
			})
		}
		setIsEditing(false)
	}

	const handleCropCancel = () => {
		setCroppedImage(null)
		setImageSrc(null)
		setIsEditing(false)
	}

	useEffect(() => {
		setShowFooter(!isEditing)
	}, [isEditing, setShowFooter])

	return (
		<>
			<S.StepWrapper>
				{isEditing ? (
					<CustomCropper
						// @ts-ignore
						className="custom-cropper"
						src={imageSrc}
						// src="https://images.unsplash.com/photo-1599140849279-1014532882fe?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1300&q=80"
						onChange={handleCropChange}
						onComplete={handleCropComplete}
						onCancel={handleCropCancel}
						stencilSize={{
							width: 400,
							height: 225
						}}
					/>
				) : (
					<>
						<S.InputContainer>
							<S.InputWrapper>
								<InputLabel>
									{t(
										'dashboard.content-modal.title',
										'Título'
									) +
										' ' +
										t(
											'dashboard.mandatory',
											'(obrigatório)'
										)}
								</InputLabel>
								<TextField
									name="title"
									value={inputs['title']}
									onChange={onChange}
									placeholder="Adicionar título"
								/>
							</S.InputWrapper>
							<S.InputWrapper>
								<InputLabel>
									{t(
										'dashboard.content-modal.description',
										'Descrição'
									) +
										' ' +
										t(
											'dashboard.mandatory',
											'(obrigatório)'
										)}
								</InputLabel>
								<RichTextField
									name="description"
									value={inputs['description']}
									onChange={onChange}
									placeholder={`Adicionar descrição d${
										collectionTypeObject.genre
									} ${collectionTypeObject.name.toLowerCase()}`}
									rows={10}
								/>
							</S.InputWrapper>
						</S.InputContainer>
						<S.InputContainer>
							<CropImage
								setImageSrc={setImageSrc}
								currentCoverImage={currentCoverImage}
								setIsEditing={setIsEditing}
							/>
						</S.InputContainer>
					</>
				)}
			</S.StepWrapper>
			{messageError && (
				<Alert sx={{ width: '100%' }} severity="error">
					{messageError}
				</Alert>
			)}
		</>
	)
}

export default EditCommonFields
