import jwt_decode from 'jwt-decode'
import history from '../history'
import { userConstants } from '../_constants'
import { userService } from '../_services'
import { alertActions } from './'

export const userActions = {
	login,
	logout,
	getForgotPassword,
	setNewPassword,
	changePassword,
	confirmEmail,
	updateUser,
	getUser,
	changePhoto,
	register,
	getAll,
	hasToken,
	delete: _delete,
	updateUserRole,
	setUser
}

function login(email, password, token, language) {
	return (dispatch) => {
		dispatch(request({ email }))
		userService.login(email, password, token).then(
			({ token }) => {
				try {
					localStorage.setItem('token', token)
					const decodedUser = jwt_decode(token)
					dispatch(success(decodedUser))
					if (language == 'es-ES') {
						history.push('/panel')
					} else {
						history.push('/painel')
					}
					//precisa atualizar a tela para trocar o menu do wordpress
					window.location.reload()
				} catch {
					const error =
						'Erro ao tentar logar, tente novamente mais tarde!'
					dispatch(failure(error))
					dispatch(alertActions.error(error, 'signin'))
				}
			},
			(error) => {
				dispatch(failure(error.toString()))
				dispatch(alertActions.error(error.toString(), 'signin'))
			}
		)
	}

	function request(token) {
		return { type: userConstants.LOGIN_REQUEST, token }
	}
	function success(user) {
		return { type: userConstants.LOGIN_SUCCESS, user }
	}
	function failure(error) {
		return { type: userConstants.LOGIN_FAILURE, error }
	}
}

function setUser(user) {
	return { type: userConstants.UPDATE_SUCCESS, user }
}

function hasToken() {
	return (dispatch) => {
		const token = localStorage.getItem('token')
		if (token) {
			try {
				const decodedUser = jwt_decode(token)
				// @ts-ignore
				const tokenExp = jwt_decode(token, { header: true }).exp
				var currentTime = Date.now() / 1000
				if (tokenExp < currentTime) {
					logout()
					dispatch(failure('Token expirado.'))
					dispatch(
						alertActions.error(
							'Sua sessão expirou, faça login novamente.',
							'signin'
						)
					)
				} else {
					dispatch(request())
					dispatch(success(decodedUser))
				}
			} catch {
				logout()
				dispatch(failure('Token inválido!'))
				dispatch(
					alertActions.error(
						'Ocorreu um erro com sua sessão, faça login novamente.',
						'signin'
					)
				)
			}
			return
		}
		dispatch(isVisitor())
	}

	function request() {
		return { type: userConstants.LOGIN_REQUEST }
	}

	function success(user) {
		return { type: userConstants.LOGIN_SUCCESS, user }
	}
	function failure(error) {
		return { type: userConstants.LOGIN_FAILURE, error }
	}
	function isVisitor() {
		return { type: userConstants.IS_VISITOR }
	}
}

function logout() {
	userService.logout()
	return { type: userConstants.LOGOUT }
}

function register(user, onUpdate = null) {
	return (dispatch) => {
		dispatch(request(user))
		return new Promise((resolve, reject) => {
			userService.register(user).then(
				() => {
					dispatch(success())
					dispatch(
						alertActions.success(
							'Verifique seu e-mail para confirmação.',
							'signup'
						)
					)
					resolve()
					if (onUpdate) {
						onUpdate()
					}
				},
				(error) => {
					reject()
					dispatch(failure(error.toString()))
					dispatch(alertActions.error(error.toString(), 'signup'))
				}
			)
		})
	}

	function request(user) {
		return { type: userConstants.REGISTER_REQUEST, user }
	}
	function success(user) {
		return { type: userConstants.REGISTER_SUCCESS, user }
	}
	function failure(error) {
		return { type: userConstants.REGISTER_FAILURE, error }
	}
}

function getForgotPassword(email) {
	return (dispatch) => {
		dispatch(request(email))

		userService.getForgotPassword(email).then(
			({ message }) => {
				dispatch(success())
				dispatch(alertActions.success(message, 'signin'))
			},
			(error) => {
				dispatch(failure(error.toString()))
				dispatch(alertActions.error(error.toString(), 'signin'))
			}
		)
	}

	function request(email) {
		return { type: userConstants.FORGOT_REQUEST, email }
	}
	function success(email) {
		return { type: userConstants.FORGOT_SUCCESS, email }
	}
	function failure(error) {
		return { type: userConstants.FORGOT_FAILURE, error }
	}
}

function updateUser(id, user) {
	return (dispatch) => {
		dispatch(request())

		userService.updateUser(id, user).then(
			(user) => {
				dispatch(success(user))
				dispatch(
					alertActions.success('Usuária/o atualizada/o com sucesso')
				)
			},
			(error) => {
				dispatch(failure())
				dispatch(alertActions.error(error.toString()))
			}
		)
	}

	function request() {
		return { type: userConstants.UPDATE_REQUEST }
	}
	function success(user) {
		return { type: userConstants.UPDATE_SUCCESS, user }
	}
	function failure() {
		return { type: userConstants.UPDATE_FAILURE }
	}
}

function getUser(id) {
	return (dispatch) => {
		dispatch(request())

		userService.getUser(id).then(
			(user) => {
				dispatch(success(user))
			},
			(error) => {
				dispatch(failure())
				dispatch(alertActions.error(error.toString()))
			}
		)
	}

	function request() {
		return { type: userConstants.GET_REQUEST }
	}
	function success(user) {
		return { type: userConstants.GET_SUCCESS, user }
	}
	function failure() {
		return { type: userConstants.GET_FAILURE }
	}
}

function setNewPassword(newPassword, passwordConfirmation, token) {
	return (dispatch) => {
		dispatch(request(token))

		userService
			.setNewPassword(newPassword, passwordConfirmation, token)
			.then(
				({ message }) => {
					dispatch(success())
					dispatch(alertActions.success(message))
				},
				(error) => {
					dispatch(failure(error.toString()))
					dispatch(alertActions.error(error.toString()))
				}
			)
	}

	function request(token) {
		return { type: userConstants.NEWPASSWORD_REQUEST, token }
	}
	function success() {
		return { type: userConstants.NEWPASSWORD_SUCCESS }
	}
	function failure(error) {
		return { type: userConstants.NEWPASSWORD_FAILURE, error }
	}
}

function confirmEmail(token) {
	return (dispatch) => {
		dispatch(request(token))

		userService.confirmEmail(token).then(
			({ message }) => {
				dispatch(success())
				dispatch(alertActions.success(message))
			},
			(error) => {
				dispatch(failure(error.toString()))
				dispatch(alertActions.error(error.toString()))
			}
		)
	}

	function request(token) {
		return { type: userConstants.CONFIRM_REQUEST, token }
	}
	function success() {
		return { type: userConstants.CONFIRM_SUCCESS }
	}
	function failure(error) {
		return { type: userConstants.CONFIRM_FAILURE, error }
	}
}

function changePassword(currentPassword, newPassword, passwordConfirmation) {
	return (dispatch) => {
		dispatch(request())

		userService
			.changePassword(currentPassword, newPassword, passwordConfirmation)
			.then(
				({ message }) => {
					dispatch(success())
					dispatch(alertActions.success(message))
				},
				(error) => {
					dispatch(failure())
					dispatch(alertActions.error(error.toString()))
				}
			)
	}

	function request() {
		return { type: userConstants.CHANGEPASSWORD_REQUEST }
	}
	function success() {
		return { type: userConstants.CHANGEPASSWORD_SUCCESS }
	}
	function failure() {
		return { type: userConstants.CHANGEPASSWORD_FAILURE }
	}
}

function changePhoto(file) {
	return (dispatch) => {
		dispatch(request())

		userService.changePhoto(file).then(
			({ message, photo_id }) => {
				dispatch(success(photo_id))
				dispatch(alertActions.success(message))
			},
			(error) => {
				dispatch(failure())
				dispatch(alertActions.error(error.toString()))
			}
		)
	}

	function request() {
		return { type: userConstants.CHANGEPHOTO_REQUEST }
	}
	function success(photo_id) {
		return { type: userConstants.CHANGEPHOTO_SUCCESS, photo_id }
	}
	function failure() {
		return { type: userConstants.CHANGEPHOTO_FAILURE }
	}
}

function getAll(page, perPage, q) {
	return (dispatch) => {
		dispatch(request())

		userService.getAll(page, perPage, q).then(
			(users) => dispatch(success(users)),
			(error) => dispatch(failure(error.toString()))
		)
	}

	function request() {
		return { type: userConstants.GETALL_REQUEST }
	}
	function success(users) {
		return { type: userConstants.GETALL_SUCCESS, users }
	}
	function failure(error) {
		return { type: userConstants.GETALL_FAILURE, error }
	}
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id, onUpdate = null) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(request(id))
			userService.delete(id).then(
				(data) => {
					dispatch(alertActions.success(data.message))
					dispatch(success(id))
					resolve()
					if (onUpdate) {
						onUpdate(data)
					}
				},
				(error) => {
					dispatch(alertActions.error(error))
					dispatch(failure(id, error.toString()))
					reject()
				}
			)
		})
	}

	function request(id) {
		return { type: userConstants.DELETE_REQUEST, id }
	}
	function success(id) {
		return { type: userConstants.DELETE_SUCCESS, id }
	}
	function failure(id, error) {
		return { type: userConstants.DELETE_FAILURE, id, error }
	}
}

function updateUserRole(userId, role, onUpdate = null) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(request())

			userService.setUserRole(userId, role).then(
				({ message }) => {
					dispatch(success())
					dispatch(alertActions.success(message))
					resolve()
					if (onUpdate) {
						onUpdate()
					}
				},
				(error) => {
					dispatch(alertActions.error(error))
					dispatch(failure(error.toString()))
					reject()
				}
			)
		})
	}

	function request() {
		return { type: userConstants.UPDATE_REQUEST }
	}
	function success() {
		return { type: userConstants.UPDATE_SUCCESS }
	}
	function failure(error) {
		return { type: userConstants.UPDATE_FAILURE, error }
	}
}
