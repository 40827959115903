import { userConstants } from '../_constants'

export function users(state = { users: [], user: {}, loading: false }, action) {
	switch (action.type) {
		case userConstants.CHANGEPASSWORD_REQUEST:
		case userConstants.CHANGEPHOTO_REQUEST:
		case userConstants.GETALL_REQUEST:
			return {
				loading: true,
				...state
			}
		case userConstants.GETALL_SUCCESS:
			return {
				rows: action.users
			}
		case userConstants.GETALL_FAILURE:
			return {
				error: action.error
			}
		case userConstants.CHANGEPHOTO_SUCCESS:
			return {
				...state,
				loading: false,
				user: {
					...state.user,
					photo_id: action.photo_id
				}
			}
		case userConstants.DELETE_SUCCESS:
		case userConstants.CHANGEPASSWORD_SUCCESS: {
			return {
				...state,
				loading: false
			}
		}
		case userConstants.UPDATE_SUCCESS:
		case userConstants.GET_SUCCESS: {
			return {
				...state,
				loading: false,
				user: action.user
			}
		}
		case userConstants.REGISTER_REQUEST:
		case userConstants.REGISTER_SUCCESS:
			return {}
		default:
			return state
	}
}
