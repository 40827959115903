import React, { useState, useRef } from 'react'
import * as S from './styles'
import Fab from '@mui/material/Fab'
import ExpandIcon from '@mui/icons-material/ZoomOutMap'
import CollapseIcon from '@mui/icons-material/ZoomInMap'

function PDFViewer({ fileId, fileContentType }) {
	const [isExpanded, setIsExpanded] = useState(false) // Track expanded state
	const containerRef = useRef(null) // Ref for the container div

	const file = process.env.REACT_APP_API.split('api')[0] + fileId
	const url = `https://docs.google.com/gview?url=${file}&embedded=true`

	const handleToggleExpanded = (e) => {
		e.stopPropagation()
		setIsExpanded(!isExpanded)
	}

	const handleCloseExpand = () => {
		setIsExpanded(false)
	}

	return (
		<>
			{fileContentType === 'application/pdf' && (
				<S.DocumentWrapper
					isExpanded={isExpanded}
					onClick={handleCloseExpand}
				>
					<S.DocumentContainer
						ref={containerRef}
						isExpanded={isExpanded}
					>
						<iframe
							src={url}
							style={{ height: '100%', width: '100%' }}
						></iframe>
						<Fab
							color="primary"
							aria-label="expand"
							onClick={handleToggleExpanded}
							style={{
								position: 'absolute',
								bottom: 20,
								right: 16
							}}
						>
							{isExpanded ? <CollapseIcon /> : <ExpandIcon />}
						</Fab>
					</S.DocumentContainer>
				</S.DocumentWrapper>
			)}
		</>
	)
}

export default PDFViewer
