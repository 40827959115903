import {
	contentConstants,
	contentsAndCollectionsConstants
} from '../_constants'
import { contentService } from '../_services'
import { alertActions } from './alert.actions'

export const contentActions = {
	getAllContent,
	getAllPendingContent,
	getAllContentByUser,
	getAllPublishedContentByUser,
	getAllContentByType,
	createContent,
	updateContent,
	deleteContent,
	publishContent,
	setTypeAndThemes,
	setFile,
	cleanContent,
	setCover,
	defineContent,
	setCurator,
	getContentById,
	getContentByIds,
	actionContent,
	getContentByUrl,
	getUserFavorites
}

function getAllContent(
	page,
	perPage,
	sortColumn,
	sortDirection,
	search = null
) {
	return (dispatch) => {
		dispatch(request())
		contentService
			.getAllContent(page, perPage, sortColumn, sortDirection, search)
			.then(
				(data) => {
					dispatch(success(data))
				},
				(error) => {
					dispatch(failure(error.toString()))
				}
			)
	}

	function request() {
		return { type: contentConstants.REQUEST }
	}
	function success(data) {
		return { type: contentConstants.SUCCESS, data }
	}
	function failure(error) {
		return { type: contentConstants.FAILURE, error }
	}
}

function getAllPendingContent(page, perPage) {
	return (dispatch) => {
		dispatch(request())
		contentService.getAllPendingContent(page, perPage).then(
			(data) => {
				dispatch(success(data))
			},
			(error) => {
				dispatch(failure(error.toString()))
			}
		)
	}

	function request() {
		return { type: contentConstants.REQUEST }
	}
	function success(data) {
		return { type: contentConstants.SUCCESS, data }
	}
	function failure(error) {
		return { type: contentConstants.FAILURE, error }
	}
}

function createContent(content, onUpdate = null, onError = null) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(request())
			contentService.createContent(content).then(
				(data) => {
					dispatch(success(data))
					dispatch(alertActions.success(data.message))
					if (onUpdate) {
						onUpdate(data)
					}
					resolve(data)
				},
				(error) => {
					dispatch(failure(error.toString()))
					dispatch(alertActions.error(error.message))
					if (onError) {
						onError(error)
					}
					reject(error)
				}
			)
		})
	}

	function request() {
		return { type: contentConstants.CREATE_REQUEST }
	}
	function success(data) {
		return { type: contentConstants.CREATE_SUCCESS, data }
	}
	function failure(error) {
		return { type: contentConstants.CREATE_FAILURE, error }
	}
}

function updateContent(contentId, content, onUpdate = null, onError = null) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(request())
			contentService.updateContent(contentId, content).then(
				(data) => {
					dispatch(success(data))
					dispatch(
						alertActions.success('Dados atualizados com sucesso!')
					)
					if (onUpdate) {
						onUpdate()
					}
					resolve(data)
				},
				(error) => {
					dispatch(failure(error.toString()))
					dispatch(alertActions.error('Erro atualizando os dados.'))
					if (onError) {
						onError()
					}
					reject(error)
				}
			)
		})
	}

	function request() {
		return { type: contentConstants.UPDATE_REQUEST }
	}
	function success(data) {
		return { type: contentConstants.UPDATE_SUCCESS, data }
	}
	function failure(error) {
		return { type: contentConstants.UPDATE_FAILURE, error }
	}
}

function deleteContent(contentId, onUpdate = null) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(request())
			contentService.deleteContent(contentId).then(
				(data) => {
					dispatch(success(contentId))
					dispatch(alertActions.success(data.message))
					resolve()
					if (onUpdate) {
						onUpdate()
					}
				},
				(error) => {
					dispatch(failure(error.toString()))
					dispatch(alertActions.error(error.message))
					reject()
				}
			)
		})
	}

	function request() {
		return { type: contentConstants.DELETE_REQUEST }
	}
	function success(id) {
		return { type: contentConstants.DELETE_SUCCESS, id }
	}
	function failure(error) {
		return { type: contentConstants.DELETE_FAILURE, error }
	}
}

function publishContent(contentId, onUpdate = null) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(request())
			contentService.publishContent(contentId).then(
				(data) => {
					dispatch(success(data))
					dispatch(alertActions.success(data.message))
					resolve()
					if (onUpdate) {
						onUpdate()
					}
				},
				(error) => {
					dispatch(failure(error.toString()))
					dispatch(alertActions.error(error.message))
					reject()
				}
			)
		})
	}

	function request() {
		return { type: contentConstants.PUBLISH_REQUEST }
	}
	function success(data) {
		return { type: contentConstants.PUBLISH_SUCCESS, data }
	}
	function failure(error) {
		return { type: contentConstants.PUBLISH_FAILURE, error }
	}
}

function setTypeAndThemes(contentId, content_type_id, themes) {
	return (dispatch) => {
		dispatch(request())
		contentService
			.setTypeAndThemes(contentId, content_type_id, themes)
			.then(
				(data) => {
					dispatch(success(data))
					dispatch(alertActions.success(data.message))
				},
				(error) => {
					dispatch(failure(error.toString()))
					dispatch(alertActions.error(error.message))
				}
			)
	}

	function request() {
		return { type: contentConstants.SET_TYPE_THEMES_REQUEST }
	}
	function success(data) {
		return { type: contentConstants.SET_TYPE_THEMES_SUCCESS, data }
	}
	function failure(error) {
		return { type: contentConstants.SET_TYPE_THEMES_FAILURE, error }
	}
}

function setFile(contentId, file, onUpdate = null, onError = null) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(request())
			contentService.setFile(contentId, file).then(
				(data) => {
					dispatch(success(data))
					// dispatch(alertActions.success(data.message))
					if (onUpdate) {
						onUpdate(data)
					}
					resolve()
				},
				(error) => {
					dispatch(failure(error.toString()))
					dispatch(alertActions.error(error.message))
					if (onError) {
						onError(error)
					}
					reject()
				}
			)
		})
	}

	function request() {
		return { type: contentConstants.SET_FILE_FAILURE }
	}
	function success(data) {
		return { type: contentConstants.SET_FILE_SUCCESS, data }
	}
	function failure(error) {
		return { type: contentConstants.SET_FILE_FAILURE, error }
	}
}

function cleanContent() {
	return (dispatch) => {
		dispatch({ type: contentConstants.CLEAN })
	}
}

function setCover(contentId, file, onUpdate = null, onError = null) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(request())
			contentService.setCover(contentId, file).then(
				(data) => {
					dispatch(success(data))
					if (onUpdate) {
						onUpdate(data)
					}
					// dispatch(alertActions.success(data.message))
					resolve()
				},
				(error) => {
					dispatch(failure(error.toString()))
					dispatch(alertActions.error(error.message))
					if (onError) {
						onError(error)
					}
					reject()
				}
			)
		})
	}

	function request() {
		return { type: contentConstants.SET_COVER_REQUEST }
	}
	function success(data) {
		return { type: contentConstants.SET_COVER_SUCCESS, data }
	}
	function failure(error) {
		return { type: contentConstants.SET_COVER_FAILURE, error }
	}
}

function defineContent(contentId, type, message, onUpdate = null) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(request())
			contentService.defineContent(contentId, type, message).then(
				(data) => {
					dispatch(success(data))
					dispatch(alertActions.success(data.message))
					resolve()
					if (onUpdate) {
						onUpdate()
					}
				},
				(error) => {
					dispatch(failure(error.toString()))
					dispatch(alertActions.error(error.message))
					reject()
				}
			)
		})
	}

	function request() {
		return { type: contentConstants.SET_REQUEST }
	}
	function success(data) {
		return { type: contentConstants.SET_SUCCESS, data }
	}
	function failure(error) {
		return { type: contentConstants.SET_FAILURE, error }
	}
}

function setCurator(contentId, curator_id, onUpdate = null) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(request())
			contentService.setCurator(contentId, curator_id).then(
				(data) => {
					dispatch(success(data, onUpdate))
					dispatch(
						alertActions.success(
							'Curador/a indicado/a com sucesso.'
						)
					)
					resolve()
				},
				(error) => {
					dispatch(failure(error.toString()))
					dispatch(alertActions.error(error.message))
					reject()
				}
			)
		})
	}

	function request() {
		return { type: contentConstants.SET_REQUEST }
	}
	function success(data, onUpdate) {
		if (onUpdate) {
			onUpdate()
		}
		return { type: contentConstants.SET_SUCCESS, data }
	}
	function failure(error) {
		return { type: contentConstants.SET_FAILURE, error }
	}
}

function getContentById(contentId) {
	if (!contentId) {
		return false
	}
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(request())
			contentService.getContentById(contentId).then(
				(data) => {
					dispatch(success(data))
					resolve(data)
				},
				(error) => {
					dispatch(failure(error.toString()))
					reject()
				}
			)
		})
	}

	function request() {
		return { type: contentConstants.CREATE_REQUEST }
	}
	function success(data) {
		return { type: contentConstants.CREATE_SUCCESS, data }
	}
	function failure(error) {
		return { type: contentConstants.CREATE_FAILURE, error }
	}
}

function getContentByIds(contentIds) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(request())
			contentService.getContentByIds(contentIds).then(
				(data) => {
					dispatch(success(data))
					resolve(data)
				},
				(error) => {
					dispatch(failure(error.toString()))
					reject()
				}
			)
		})
	}

	function request() {
		return { type: contentConstants.REQUEST }
	}
	function success(data) {
		return { type: contentConstants.SUCCESS, data }
	}
	function failure(error) {
		return { type: contentConstants.FAILURE, error }
	}
}

function getAllContentByUser(
	page,
	perPage,
	sortColumn,
	sortDirection,
	idUser,
	search,
	onlyPublished = false
) {
	return (dispatch) => {
		dispatch(request())

		contentService
			.getAllContentByUser(
				page,
				perPage,
				sortColumn,
				sortDirection,
				idUser,
				search,
				onlyPublished
			)
			.then(
				(data) => dispatch(success(data)),
				(error) => dispatch(failure(error.toString()))
			)
	}

	function request() {
		return { type: contentConstants.USER_CONTENT_REQUEST }
	}
	function success(data) {
		return { type: contentConstants.USER_CONTENT_SUCCESS, data }
	}
	function failure(error) {
		return { type: contentConstants.USER_CONTENT_FAILURE, error }
	}
}

function getAllPublishedContentByUser(
	page,
	perPage,
	sortColumn,
	sortDirection,
	idUser,
	search
) {
	return getAllContentByUser(
		page,
		perPage,
		sortColumn,
		sortDirection,
		idUser,
		search,
		true
	)
}

function getAllContentByType(page, perPage, idType) {
	return (dispatch) => {
		dispatch(request())

		if (idType === 'recent') {
			contentService.getAllRecentContent(page, perPage).then(
				(data) => dispatch(success(data)),
				(error) => dispatch(failure(error.toString()))
			)
			return
		}

		contentService.getAllContentByType(page, perPage, idType).then(
			(data) => dispatch(success(data)),
			(error) => dispatch(failure(error.toString()))
		)
	}

	function request() {
		return { type: contentConstants.CONTENT_TYPE_REQUEST, id: idType }
	}
	function success(data) {
		return { type: contentConstants.CONTENT_TYPE_SUCCESS, id: idType, data }
	}
	function failure(error) {
		return { type: contentConstants.CONTENT_TYPE_FAILURE, error }
	}
}

function actionContent(action, contentId) {
	return (dispatch) => {
		dispatch(request())

		contentService.actionContent(action, contentId).then(
			(data) => dispatch(success(data)),
			(error) => dispatch(failure(error.toString()))
		)
	}

	function request() {
		return {
			type: contentConstants.CONTENT_ACTION_REQUEST,
			action,
			id: contentId
		}
	}
	function success(data) {
		return {
			type: contentsAndCollectionsConstants.ACTION_SUCCESS,
			action,
			data,
			contentId
		}
	}
	function failure(error) {
		return {
			type: contentConstants.CONTENT_ACTION_FAILURE,
			action,
			error,
			id: contentId
		}
	}
}

function getContentByUrl(url) {
	return (dispatch) => {
		dispatch(request(url))

		contentService.getContentByUrl(url).then(
			(data) => dispatch(success(data)),
			() => dispatch(failure())
		)
	}

	function request(url) {
		return {
			type: contentConstants.REQUEST_CONTENT_URL,
			url
		}
	}
	function success(data) {
		return {
			type: contentConstants.SUCCESS_CONTENT_URL,
			data
		}
	}
	function failure() {
		return {
			type: contentConstants.FAILURE_CONTENT_URL
		}
	}
}

function getUserFavorites(page, perPage) {
	return (dispatch) => {
		dispatch(request())

		contentService.getUserFavorites(page, perPage).then(
			(data) => dispatch(success(data)),
			() => dispatch(failure())
		)
	}

	function request() {
		return {
			type: contentConstants.REQUEST
		}
	}
	function success(data) {
		return {
			type: contentConstants.SUCCESS_FAVORITE,
			data
		}
	}
	function failure() {
		return {
			type: contentConstants.FAILURE
		}
	}
}
