import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { userActions } from '../../../app/_actions'
import { ReactComponent as LoginIcon } from '../../../assets/icons/login.svg'
import * as S from './styles'

function LoginButton({ onClick: onSignInClick }) {
	const { t, i18n } = useTranslation()
	// @ts-ignore
	const { loggedIn, user } = useSelector((state) => state.authentication)
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(userActions.hasToken())
	}, [dispatch])

	const genericGoTo = (path = '/') => {
		if (i18n.language == 'es-ES') {
			return `/panel${path}`
		}

		return `/painel${path}`
	}

	const goToPanel = () => {
		return genericGoTo()
	}

	const goToProfileSettings = () => {
		return genericGoTo('/minha-conta/perfil')
	}

	return loggedIn ? (
		<S.LoginButtonWrapper>
			<S.LoginUser href={goToProfileSettings()}>{user.name}</S.LoginUser>
			<S.LoginButton
				style={{ backgroundColor: 'rgba(255, 255, 255, 0.20)' }}
				href={goToPanel()}
			>
				{t('login-button.panel', 'Painel')}
			</S.LoginButton>
		</S.LoginButtonWrapper>
	) : (
		<S.LoginButton onClick={onSignInClick}>
			<LoginIcon
				// @ts-ignore
				name="sign-in"
				style={{
					color: '#fff',
					fontSize: '16px'
				}}
			/>
			<S.LoginButtonText>Entrar</S.LoginButtonText>
		</S.LoginButton>
	)
}
export default LoginButton
